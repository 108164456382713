import React, { useEffect, useState, useRef } from 'react';
import { Button, ButtonGroup, Container, Table } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useNavigate  } from 'react-router-dom';
import {BASE_API_URL} from "../config";

const GroupList = () => {

  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(['XSRF-TOKEN']);
  const dataFetchedRef = useRef(false);
   const history = useNavigate();
  
  let response="";

  useEffect(() => {
    setLoading(true);
     if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/bankers`, {
		        method: 'GET',
		        //   cache: 'no-cache',
                // mode: 'no-cors',
                // redirect: 'follow',
        credentials:'include'
		       
		    })    
      .then(response => response.json())
      .then(data => {
        setGroups(data);
        setLoading(false);
      }).catch(function(error){
           console.log("Error :" + error);
           console.log("Response Code: " + response.status );
		 history('/');
         })
      
      
     
  }, []);

  const remove = async (id) => {
   /* let response = await fetch(`/api/bankers/${id}`, {
      method: 'DELETE',
      headers: {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }).then(() => {	  
      let updatedGroups = [...groups].filter(i => i.id !== id);
      setGroups(updatedGroups);
    }).catch(err => {
      console.log(err.response.data);
    });*/
    
    
    let response = await fetch(BASE_API_URL + '/api/bankers/'+id, {
		       method: 'DELETE',
      headers: {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    });
    
    if(response.status == 403){
				alert("Not Authorized to delete an baker!!!!");
			}
    
    
     if(response.status === 204){
			 let updatedGroups = [...groups].filter(i => i.id !== id);
			 console.log("I am here...");
      		 setGroups(updatedGroups);
		}
    
    if(response.status != 200){ 
	           	console.log("Error: "+ response.message);
	 }	
    
  }

  if (loading) {
    return <p>Loading...</p>;
  }
  
   
 

  const groupList = groups.map(group => {
	
	let activationStatus = "Not Defined";
	
	 if(group.active=== 'N'){
		activationStatus = <Button size="sm" color="primary" tag={Link} to={"/activate/" + group.id}>Activate</Button>;
	 } else if(group.active=== 'P'){
	   activationStatus = "Pending Activation";
	   } else if(group.active=== 'Y'){
		activationStatus = "Activated";
		} else{
			activationStatus = "";
		}
	    
	 
    
    return <tr key={group.id}>
      <td style={{whiteSpace: 'nowrap'}}>{group.firstName} {group.lastName}</td>     
      <td>{group.email}</td>
      <td>{group.title}</td>
      <td>{group.bankingVetical}</td>
      <td>{group.branchName}</td>
      <td>{group.department}</td>
      <td>{activationStatus}</td>
      <td>
        <ButtonGroup>
          <Button size="sm" color="primary" tag={Link} to={"/bankers/" + group.id}>Edit</Button> &nbsp;&nbsp;
          <Button size="sm" color="danger" onClick={() => remove(group.id)}>Delete</Button>&nbsp;&nbsp;
          
        </ButtonGroup>
      </td>
    </tr>
  });
  

  
  return (
	
	
	
    <div>
      <AppNavbar/>
      <Container fluid>
        <div className="float-end">
          <Button color="success" tag={Link} to="/bankers/import">Upload Bankers</Button> &nbsp;&nbsp;&nbsp;
          <Button color="success" tag={Link} to="/bankers/new">Add Banker</Button>
        </div>
        <h3>Manage Bankers</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="15%">Name</th>
            <th width="17%">Email</th>            
            <th width="10%">Title</th>
            <th width="10%">Domain</th>
            <th width="10%">Branch</th>
            <th width="10%">Department</th>    
            <th width="10%">Active User</th>       
          </tr>
          </thead>
          <tbody>
          {groupList}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default GroupList;
