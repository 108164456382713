import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {BASE_API_URL} from "../config";


const BankerImport = () => {
	 const [file, setFile] = useState(null);
	 const [authenticated, setAuthenticated] = useState(false);
	 const [loading, setLoading] = useState(false);
	 const [uploadFileState, setUploadFileState] = useState('<h2>Please upload a file</h2>');
	 const history = useNavigate();
	 let dataFetchedRef = useRef(false);
	 
	 useEffect(() => {
    setLoading(true);
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
      .then(response => response.text())
      .then(body => {
        if (body === '') {
          setAuthenticated(false);
          console.log("User not authenticated, redirecting the user to homepage");
           history('/');
        } else {        
          setAuthenticated(true);
        }
        setLoading(false);
      });
  }, [])  
  	
  	const handleSubmit = async e => {
    e.preventDefault();  
    //var fileUploadSuccess = document.querySelector('#FileUploadSuccess');
    //fileUploadSuccess.innerHTML = "<h2>Parsing!!!!, please wait........</h2>";
        setUploadFileState('<h2>Parsing!!!!, please wait........</h2>');
    
    
    let res = await uploadFile(file);
    console.log(res);
    
     //fileUploadSuccess.innerHTML = "<li>Uploaded File Name : " + res.fileName +"</li>" +
    	// 						  "<li>Processed file download URL :  " + res.fileDownloadUri +"</li>";
        setUploadFileState(`<li>Uploaded File Name : ${res.fileName}</li><li>Processed file download URL : ${res.fileDownloadUri}</li>`);
    
  };
  
   const notAuthorized = <h2>Not Authorized!!!</h2>;
   
   const uploadFile = async file => {
    const formData = new FormData();
    formData.append("file", file);
    console.log("File Name:" + file); 
   // var fileUploadSuccess = document.querySelector('#FileUploadSuccess');
    
     // send fetch along with cookies
			let response = await fetch(`${BASE_API_URL}/api/excel/uploadFile`, {
		        method: 'POST',
                credentials: 'include',
		        body: formData
		    });
		    
		    
		    if(response.status === 403){
				setUploadFileState("<h1>Not Authorized!!!!<h1>");
			}
		    
		    
		    // server responded with http response != 200
	    	if(response.status !== 200)
	    		throw new Error('HTTP response code != 200');
	    		
	    	// read json response from server
	    	// success response example : {"error":0,"message":""}
	    	// error response example : {"error":1,"message":"File type not allowed"}
	    	let json_response = await response.json();
	    	
	        if(json_response.error === 1)
	           	throw new Error(json_response.message);	
	           	
	           	return json_response;
  };
  
   const handleOnChange = e => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

	 
	 
	 return (<div>
	 <AppNavbar/>
	  <Container>
     
         
         
         <div className="upload-container">
            <div className="upload-header">
                <h2>Bulk upload bankers</h2>
            </div>
            <br/><br/>
            <div className="upload-content">
                 <Form onSubmit={handleSubmit} encType="multipart/form-data">
                 		<FormGroup>
            				<Label for="file">Uplodad an excel file</Label>
           			 		<Input type="file" onChange={handleOnChange} name="file"  className="file-input"  required  />
          				</FormGroup>
		               <FormGroup>
		            		<Button color="primary" type="submit">Upload</Button>{' '}
		           			 <Button color="secondary" tag={Link} to="/bankers">Cancel</Button>
		          		</FormGroup>
          		</Form>
                
            </div>
        </div>
          <br/>
	<br/>
	<div style={{width:'60%',height:'20%'}} align="left" id="FileUploadSuccess" className="upload-container" dangerouslySetInnerHTML={{__html:uploadFileState}}>

    </div>
         
              
         </Container>    
	
	 </div>)
};

export default BankerImport;