import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { useParams, useNavigate, Link } from 'react-router-dom';
 
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import {BASE_API_URL} from "../config";
 
 
let synonyms = undefined;
let synonymsArray = undefined;
const SynonymEdit = () => {
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [groups, setGroups] = useState([]);
	const { id } = useParams();	
	const navigate = useNavigate();
	
   
   useEffect(() => {
    setLoading(true);
     if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/synomymsbytmid/`+id, {
		        method: 'GET',
		        //   cache: 'no-cache',
                // mode: 'no-cors',
                // redirect: 'follow'
                credentials: 'include'
		       
		    })    
      .then(response => response.json())
      .then(data => { 
        synonyms = data.synonyms;
        setGroups(synonyms.split(',')); 
        setLoading(false);
      }).catch(function(error){
           console.log("Error :" + error); 
         })
      
      
     
  }, []);
  
   const handleBack = async (event) => {
    event.preventDefault(); 
    navigate('/bankers/pmapping');
    
    }
   
   const remove = async (synonym) => {
  		console.log(synonym);
  		let response = await fetch(BASE_API_URL + '/api/synomym/'+synonym, {
		       method: 'DELETE',      
      		credentials: 'include'
    });
    
    if(response.status == 403){
				alert("Not Authorized to delete an synonym!!!!");
			}
    
    
     if(response.status === 204){
			alert("synonym : " + synonym + "deleted sucessfully");
			 window.location.reload();
		}
    
    if(response.status != 200){ 
	           	console.log("Error: "+ response.message);
	 }	
    
  }
  
  if (loading) {
    return <p>Loading...</p>;
  }
   
  

  return (<div>
      <AppNavbar/>
      <Container>
      <div> 
      		 <Table className="mt-4">
		          <thead>
		          <tr>
		            <th width="40%">Synonym Name</th>		            
		            <th width="20%">Actions</th>
		          </tr>
		          </thead>
		          <tbody> 
		          	  {groups.map((name, i) => (
				          <tr key={i}>
				            <td style={{whiteSpace: 'nowrap'}}>{name}</td>   
				            <td>
						        <ButtonGroup>						         
						          <Button size="sm" color="danger" onClick={() => remove(name)}>Delete</Button>&nbsp;&nbsp;
						          
						        </ButtonGroup>
					        </td>
				          </tr> 
				        ))}  
		          </tbody>
	        </Table> 
	        <p>
	        <Button color="secondary" onClick={handleBack}>Back</Button>
	        </p>
       	</div>
        
      </Container>
    </div>
  )
};

export default SynonymEdit;
