import React from 'react'
import { Container, Typography, Grid, TextField, Button } from '@material-ui/core'

const CTLDetails = ({ prevStep, nextStep, handleChange, values }) => {
  
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
    <Container  component="main" maxWidth="xs">
      <div>
        <Typography  component="h1" variant="h5">
          Generate Presentation - Client Details
        </Typography>
        <form>
          <Grid container spacing={2}>

            {/* first name */}
            <Grid item xs={12} sm={6}>
              <TextField 
                placeholder="First Name"
                label="First Name"
                onChange={handleChange('cfirstname')}
                defaultValue={values.cfirstname}
              />
            </Grid>
            {/* last name */}
            <Grid item xs={12} sm={6}>
              <TextField 
                placeholder="Last Name"
                label="Last Name"
                onChange={handleChange('clastname')}
                defaultValue={values.clastname}
              />
            </Grid>
            
             {/* Department */}
            <Grid item xs={12} sm={6}>
              <TextField 
                placeholder="Title"
                label="Title"
                onChange={handleChange('ctitle')}
                defaultValue={values.ctitle}
                autoComplete="Title"
                fullWidth
              />
            </Grid>

            {/* Department */}
            <Grid item xs={12} sm={6}>
              <TextField 
                placeholder="Department"
                label="Department"
                onChange={handleChange('cdepartment')}
                defaultValue={values.cdepartment}
                autoComplete="Customer Department"
                fullWidth
              />
            </Grid>

            {/* Email */}
            <Grid item xs={12}>
              <TextField 
                placeholder="Email"
                label="Email"
                onChange={handleChange('cemail')}
                defaultValue={values.cemail}
                autoComplete="Email"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                placeholder="Mobile"
                label="Mobile"
                onChange={handleChange('cmobile')}
                defaultValue={values.cmobile}
                autoComplete="Mobile"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <TextField 
                placeholder="client logo"
                label="Client logo URL"
                onChange={handleChange('clogo')}
                defaultValue={values.clogo}
                autoComplete="Cleint Logo"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Previous }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Continue }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
        <img src="/Introslide.jpg"  width="100%"/>
      </div>
    </Container>
  )
}

export default CTLDetails