import React, { useEffect, useState, useRef } from 'react';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useNavigate  } from 'react-router-dom';
import {BASE_API_URL} from "../config";

const GroupList = () => {
	
  const [file, setFile] = useState(null);  
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cookies] = useCookies(['XSRF-TOKEN']);
   const dataFetchedRef = useRef(false);
   const history = useNavigate();
 

  
  let response="";

  useEffect(() => {
    setLoading(true);
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    let apiResponse = fetch(`${BASE_API_URL}/api/tm-services`, {
		        method: 'GET',
		        // redirect: "manual",
		        //   cache: 'no-cache'  ,
        credentials: 'include'
		    })    
      .then(response => response.json())
      .then(data => {
        setGroups(data);
        setLoading(false);
      }).catch(function(error){		
           console.log("Error :" + error);
           history('/');
         })  
         
         console.log(apiResponse);
         
  }, []);

   
  if (loading) {
    return <p>Loading...</p>;
  }
  
 
  
  const handleOnChange = e => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };
  
  const handleSubmit = async e => {
    e.preventDefault();   
   console.log("Recomendation:" + e.target.recomendation.value);
    let res = await uploadFile(file, e.target.recomendation.value); 
    console.log("Response :" + res);
     window.location.reload();
  }  
  
  
  
  const uploadFile = async (file, recomendation) => {    
    const formData = new FormData();
    console.log("File Name:" + file.name);
    console.log("Recomendation:" + recomendation);
    
    formData.append("file", file);
    formData.append("recomendation", recomendation);   
      
    
     		// send fetch along with cookies
			let response = await fetch(BASE_API_URL + '/api/recommendation/uploadFile', {
		        method: 'POST',		       
		        body: formData,
                credentials: 'include'
		    });
		    
		    
		    if(response.status===400){
			  alert("Bad Request, please note that only powerpoint files are supported");
			}
		    
		     // server responded with http response != 200
	    	if(response.status != 200 && response.status!=400) {
				alert("Failed to upload recomendation ppt file, please contact support");				
	    		throw new Error('HTTP response code != 200');
	    	}
	    		
	    	// read json response from server
	    	// success response example : {"error":0,"message":""}
	    	// error response example : {"error":1,"message":"File type not allowed"}
	    	let json_response = await response.json();
	    	
	        if(json_response.error == 1)
	           	throw new Error(json_response.message);	
	           	
	           	return json_response; 
  }
  
  const remove = async (name) => {
	let recomendationFileName = name.replaceAll("/", "-");
	recomendationFileName = name.replaceAll(" ", "-").toLowerCase()+".pptx";
    await fetch(`${BASE_API_URL}/api/tm-services/`+recomendationFileName, {
      method: 'DELETE',
      headers: {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }).then(() => {
      console.log("Deleted..");
       window.location.reload();
    });
  }
 
 

  const groupList = groups.map(group => {
	 
	if(group.active==='Y'){
			let recomendationFileDetails = "Not Defined";
			let actionDetails = "Not Defined";
			if(group.location !== null){
				recomendationFileDetails = <a href={group.location}>{group.location}</a>;
				 actionDetails = <Button size="sm" color="danger" onClick={() => remove(group.name)}>Delete</Button>;
			} else {
				actionDetails = "";
				recomendationFileDetails =   <div className="upload-content"> 
		                 
		                 <Form onSubmit={handleSubmit} encType="multipart/form-data">
				               <FormGroup>
				               		<Label for="file">Uplodad an pptx file</Label>  
				               		<Input type="file"  onChange={handleOnChange} name="file"  className="file-input"  required  />    
				               		<Input type="hidden"  name="recomendation" value = {group.name}  required  />
				            		<Button color="primary" type="submit">Upload</Button>{' '}
				          		</FormGroup>
		          		  </Form>
		            </div>;
			}
		    
		    return <tr key={group.id}>
		      <td style={{whiteSpace: 'nowrap'}}>{group.name} </td>     
		       <td style={{whiteSpace: 'nowrap'}}> 
		       {recomendationFileDetails} 
		       </td> 
		      <td style={{whiteSpace: 'nowrap'}}>
		       {/*<td style={{whiteSpace: 'nowrap'}}> */}
		       {actionDetails} 
		       {/*</td>*/}
		        <ButtonGroup>
		          
		        </ButtonGroup>
		      </td>
		    </tr>
		 }
  });
  
  
  
  return (
    <div>
      <AppNavbar/>
      <Container fluid> 
      <div className="float-end">     
     		 <Button color="success" tag={Link} to="/recommend/mapping">Manage Recommendation Mapping</Button> 
          		&nbsp;&nbsp;&nbsp;
          	 <Button color="success" tag={Link} to="/recommend/new">Add Recommendation</Button>
        </div>
        <h3>Manage TM Services Recomendation Meta Data</h3>
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="20%">Name</th>
            <th width="70%">Upload Recomendation Slides</th>
            <th width="10%">Actions</th>
          </tr>
          </thead>
          <tbody>
          {groupList}
          </tbody>
        </Table>
      </Container>
    </div>
  );
};

export default GroupList;
