import React from 'react'
import {
    Container,
    Typography,
    Grid,
    TextField,
    Button,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'

const ChooseViewPoints = ({prevStep, nextStep, handleChange, values}) => {

    const [checked, setChecked] = React.useState(true);

    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }


    return (
        <Container component="main" maxWidth="xs">
            <div>
                <Typography component="h3" variant="h6">
                    Generate Presentation - Choose Rendering Options
                </Typography>
                <br/>
                <form>
                    {/*{ <!--First Section-->}*/}
                    <Grid container spacing={2}>
                        {/*{!--CheckBox--}*/}
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel id='pie'
                                              control={<Checkbox checked={values.ispieview}   onChange={handleChange('ispieview')}/>}
                                              label="Pie Chart View"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel id='smart'
                                              control={<Checkbox checked={values.issmartview}   onChange={handleChange('issmartview')}/>}
                                              label="Smart Chart View"/>
                        </Grid>
                        {/*{!--Checkbox End--}*/}
                    </Grid>
                    <Grid>
                        <ImageList variant="masonry" cols={2} gap={15}>
                            <ImageListItem>
                                <img
                                    src="/PieChartSample.JPG"
                                    alt="Pie Chart"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Pie Chart"/>
                            </ImageListItem>
                            <ImageListItem>
                                <img
                                    src="/SmartChartSample.JPG"
                                    alt="Smart Chart"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Smart Chart"/>
                            </ImageListItem>
                        </ImageList>
                    </Grid>
                    {/*{ <!--First Section End-->}*/}
                    <br/> <br/>

                    {/*{ <!--##########################################################################################-->}*/}

                    {/*{!--Second Section Start--}*/}
                    {/*{ <!--Checkbox Start-->}*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel id='stats'
                                              control={<Checkbox checked={values.isstatsview} onChange={handleChange('isstatsview')}/>}
                                              label="Summary Chart View"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <FormControlLabel id='recommendation'
                                              control={<Checkbox checked={values.isrecomendedview} onChange={handleChange('isrecomendedview')}/>}
                                              label="Recommendation View"/>
                        </Grid>
                    </Grid>
                    {/*{!--Checkbox End--}*/}
                    {/*{ <!--Image Content Start-->}*/}
                    <Grid>
                        <ImageList variant="masonry" cols={2} gap={15}>
                            <ImageListItem>
                                <img
                                    src="/SummaryViewSample.JPG"
                                    alt="Stats View"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Stats View"/>
                            </ImageListItem>
                            <ImageListItem>
                                <img
                                    src="/RecommendationViewSample.JPG"
                                    alt="Recommendation View"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Recommendation View"/>
                            </ImageListItem>
                        </ImageList>
                    </Grid>
                    {/*{!--Image Content End--}*/}
                    {/*{ <!--Second Section End-->}*/}
                    <br/><br/>
                    {/*{!--##########################################################################################--}*/}

                    {/*{ <!--Third Section Start-->}*/}
                    {/*{ <!--Checkbox Start-->}*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel id='smartart'
                                              control={<Checkbox checked={values.issmartartview}
                                                  onChange={handleChange('issmartartview')}/>}
                                              label="Smart Art View"/>
                        </Grid>
                        <Grid item xs={12} sm={6}>

                            <FormControlLabel id='smartsrcv'
                                              control={<Checkbox checked={values.issmartsrcvview}
                                                                 onChange={handleChange('issmartsrcvview')}/>}
                                              label="Smart Service View"/>
                        </Grid>
                    </Grid>
                    {/*{ <!--Checkbox End-->}*/}
                    {/*{ <!--Image Content Start-->}*/}
                    <Grid>
                        <ImageList variant="masonry" cols={2} gap={15}>
                            <ImageListItem>
                                <img
                                    src="/SmartProtectViewSample.JPG"
                                    alt="Smart Art View"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Smart Art View"/>
                            </ImageListItem>
                            <ImageListItem>
                                <img
                                    src="/SmartServiceViewSample.JPG"
                                    alt="Smart Service View"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Smart Service View"/>
                            </ImageListItem>
                        </ImageList>
                    </Grid>
                    {/*{!--Image Content End--}*/}
                    {/*{!--Third Section End--}*/}
                    <br/><br/>
                    {/*{!--##########################################################################################--}*/}

                    {values.workingCapital &&
                    <>
                        {/*{!--Fourth Section Start--}*/}
                        {/*{!--Checkbox Start--}*/}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel id='WorkingCapitalMapWithInventory'
                                                  control={<Checkbox checked={values.workingCapitalWithInventory}
                                                      onChange={handleChange('workingCapitalWithInventory')}/>}
                                                  label="Working Capital Map With Inventory"/>
                            </Grid>
                            {
                                values.inventory && values.inventory > 0 &&
                                <Grid item xs={12} sm={6}>
                                    <FormControlLabel id='workingCapitalWithoutInventory'
                                                      control={<Checkbox checked={values.workingCapitalWithoutInventory}
                                                                         onChange={handleChange('workingCapitalWithoutInventory')}/>}
                                                      label="Working Capital without Inventory"/>
                                </Grid>
                            }
                        </Grid>
                        {/*{!--Checkbox End--}*/}
                        {/*{ <!--Image Content Start-->}*/}
                        <Grid>
                            <ImageList variant="masonry" cols={2} gap={15}>
                                <ImageListItem>
                                    <img
                                        src="/WorkingCapitalMapWithInventory.JPG"
                                        alt="WorkingCapitalMapWithInventory"
                                        loading="lazy"
                                    />
                                    <ImageListItemBar title="Working Capital Inventory"/>
                                </ImageListItem>
                                {
                                    values.inventory && values.inventory > 0 &&
                                    <ImageListItem>
                                        <img
                                            src="/WorkingCapitalMapWithoutInventory.JPG"
                                            alt="WorkingCapitalWithoutInventory"
                                            loading="lazy"
                                        />
                                        <ImageListItemBar title="Working Capital Without Inventory"/>
                                    </ImageListItem>
                                }
                            </ImageList>
                        </Grid>
                        {/*{!--Image Content End--}*/}
                        {/*{!--Fourth Section End--}*/}
                        {/*{!--##########################################################################################--}*/}

                        <br/><br/>
                        {/*{!--Fifth Section Start--}*/}
                        {/*{!--Checkbox Start--}*/}
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel id='workingCapitalCCCDateWithInventory'
                                                  control={<Checkbox checked={values.workingCapitalCCCDateWithInventory}
                                                      onChange={handleChange('workingCapitalCCCDateWithInventory')}/>}
                                                  label="Working Capital CCC Date With Inventory"/>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControlLabel id='workingCapitalCCCAmountWithInventory'
                                                  control={<Checkbox checked={values.workingCapitalCCCAmountWithInventory}
                                                      onChange={handleChange('workingCapitalCCCAmountWithInventory')}/>}
                                                  label="Working Capital CCC Amount With Inventory"/>
                            </Grid>
                        </Grid>
                        {/*{!--Checkbox End--}*/}
                        {/*{ <!--Image Content Start-->}*/}
                        <Grid>
                            <ImageList variant="masonry" cols={2} gap={15}>
                                <ImageListItem>
                                    <img
                                        src="/WorkingCapitalCCCDateWithInventory.JPG"
                                        alt="WorkingCapitalCCCDateWithInventory"
                                        loading="lazy"
                                    />
                                    <ImageListItemBar title="Working Capital CCC Date With Inventory"/>
                                </ImageListItem>

                                <ImageListItem>
                                    <img
                                        src="/WorkingCapitalCCCAmountWithInventory.JPG"
                                        alt="WorkingCapitalCCCAmountWithInventory"
                                        loading="lazy"
                                    />
                                    <ImageListItemBar title="Working Capital CCC Amount With Inventory"/>
                                </ImageListItem>
                            </ImageList>
                        </Grid>
                        {/*{!--Image Content End--}*/}
                        {/*{!--Fifth Section End--}*/}
                        {/*{!--##########################################################################################--}*/}
                        {
                            values.inventory && values.inventory > 0 &&
                            <>
                                <br/><br/>
                                {/*{!--Fifth Section Start--}*/}
                                {/*{!--Checkbox Start--}*/}
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel id='workingCapitalCCCDateWithoutInventory'
                                                          control={<Checkbox checked={values.workingCapitalCCCDateWithoutInventory}
                                                                             onChange={handleChange('workingCapitalCCCDateWithoutInventory')}/>}
                                                          label="Working Capital CCC Date With Inventory"/>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormControlLabel id='workingCapitalCCCAmountWithoutInventory'
                                                          control={<Checkbox checked={values.workingCapitalCCCAmountWithoutInventory}
                                                                             onChange={handleChange('workingCapitalCCCAmountWithoutInventory')}/>}
                                                          label="Working Capital CCC Amount Without Inventory"/>
                                    </Grid>
                                </Grid>
                                {/*{!--Checkbox End--}*/}
                                {/*{ <!--Image Content Start-->}*/}
                                <Grid>
                                    <ImageList variant="masonry" cols={2} gap={15}>
                                        <ImageListItem>
                                            <img
                                                src="/WorkingCapitalCCCDateWithoutInventory.JPG"
                                                alt="WorkingCapitalCCCDateWithoutInventory"
                                                loading="lazy"
                                            />
                                            <ImageListItemBar title="Working Capital CCC Date Without Inventory"/>
                                        </ImageListItem>

                                        <ImageListItem>
                                            <img
                                                src="/WorkingCapitalCCCAmountWithoutInventory.JPG"
                                                alt="WorkingCapitalCCCAmountWithoutInventory"
                                                loading="lazy"
                                            />
                                            <ImageListItemBar title="Working Capital CCC Amount Without Inventory"/>
                                        </ImageListItem>
                                    </ImageList>
                                </Grid>
                                {/*{!--Image Content End--}*/}
                                {/*{!--Fifth Section End--}*/}
                            </>
                        }

                    </>
                    }


                    {/*{!--Fourth Section Start--}*/}
                    {/*{!--Checkbox Start--}*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <FormControlLabel id='account'
                                              control={<Checkbox checked={values.isaccountview}
                                                  onChange={handleChange('isaccountview')}/>}
                                              label="Account View"/>
                        </Grid>
                    </Grid>
                    {/*{!--Checkbox End--}*/}
                    {/*{ <!--Image Content Start-->}*/}
                    <Grid>
                        <ImageList variant="masonry" cols={2} gap={15}>
                            <ImageListItem>
                                <img
                                    src="/AccountViewSample.JPG"
                                    alt="Account View"
                                    loading="lazy"
                                />
                                <ImageListItemBar title="Account View"/>
                            </ImageListItem>
                        </ImageList>
                    </Grid>
                    {/*{!--Image Content End--}*/}
                    {/*{!--Fourth Section End--}*/}

                    <br/> <br/>
                    {/*{ <!--Button Content Start-->}*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Previous}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Continue}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                    {/*{!--Button Content End--}*/}
                </form>
            </div>
        </Container>
    )
}

export default ChooseViewPoints