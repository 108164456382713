import React, {useState} from 'react';
import {
    Container,
    Grid,
    Typography,
    FormControlLabel,
    Input,
    TextField,
    Radio,
    RadioGroup,
    Button
} from "@material-ui/core";

const WorkingCapitalDetails = ({prevStep, nextStep, handleChange, values}) => {
    const [company, setCompany] = useState('');
    const [disableFields, setDisableFields] = useState(true);
    const Continue = e => {
        e.preventDefault();
        nextStep();
    }

    const Previous = e => {
        e.preventDefault();
        prevStep();
    }

    const setFormDataChange = input => e => {
        const value = e.target.value;
        switch (input) {
            case 'company':
                setCompany(value);
                break;
            case 'use-working-capital':
                setDisableFields(value === 'no');
                break;
        }
    }


    return <>
        <Container component="main" maxWidth="xs">
            <div>
                <Typography component="h3" variant="h6">
                    Generate Presentation - Input Working capital Data
                </Typography>
                <br/>
                <form>
                    <Grid container spacing={2}>
                        <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            name="radio-buttons-group" value={!values.workingCapital ? 'no' : 'yes'}>
                            <Grid item xs={12} >
                                <FormControlLabel id='yes' value="yes"
                                                  control={<Radio onChange={handleChange('workingCapital')} />} label="Generate Working Capital" />
                            </Grid>
                            <Grid item xs={12}>
                                <FormControlLabel id='no' value="no"
                                                  control={<Radio onChange={handleChange('workingCapital')} />} label="Leave Working Capital Data" />
                            </Grid>
                        </RadioGroup>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                placeholder="Company Name"
                                label="Company Name"
                                onChange={handleChange('company')}
                                defaultValue={values.company}
                                value={values.company}
                                // variant="outlined"
                                autoComplete="Company"
                                required={true}
                                fullWidth
                                disabled={!values.workingCapital}
                                onChange={handleChange('company')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Total Sale/Revenue"
                                label="Total Sale/Revenue"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.totalSaleRevenue}
                                value={values.totalSaleRevenue}
                                autoComplete="Total Sale/Revenue"
                                fullWidth
                                required={true} disabled={!values.workingCapital}
                                onChange={handleChange('totalSaleRevenue')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Cost of Good Sold"
                                label="Cost of Good Sold"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.cogs}
                                value={values.cogs}
                                autoComplete="Cost of Good Sold"
                                fullWidth
                                required={true} disabled={!values.workingCapital}
                                onChange={handleChange('cogs')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Account Receivables"
                                label="Account Receivables"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.accountReceivables}
                                value={values.accountReceivables}
                                autoComplete="Account Receivables"
                                fullWidth
                                required={true} disabled={!values.workingCapital}
                                onChange={handleChange('accountReceivables')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Account Payables"
                                label="Account Payables"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.accountPayables}
                                value={values.accountPayables}
                                autoComplete="Account Payables"
                                fullWidth
                                required={true}
                                disabled={!values.workingCapital}
                                onChange={handleChange('accountPayables')}
                            />
                        </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Loan Int Rate"
                                label="Loan Int Rate"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.loanIntRate}
                                value={values.loanIntRate}
                                autoComplete="Loan Int Rate"
                                fullWidth disabled={!values.workingCapital}
                                onChange={handleChange('loanIntRate')}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Investment Int Rate"
                                label="Investment Int Rate"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.investmentIntRate}
                                value={values.investmentIntRate}
                                autoComplete="Investment Int Rate"
                                fullWidth disabled={!values.workingCapital}
                                onChange={handleChange('investmentIntRate')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                placeholder="Inventory"
                                label="Inventory"
                                // onChange={setFormDataChange('company')}
                                // defaultValue={company}
                                // variant="outlined"
                                defaultValue={values.inventory}
                                value={values.inventory}
                                autoComplete="Inventory"
                                fullWidth disabled={!values.workingCapital}
                                onChange={handleChange('inventory')}
                            />
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                            <Typography component="p" variant="p" className={"text-danger text-end"}>Fields marked with * are mandatory</Typography>
                        </Grid>
                    </Grid>

                    {/*{ <!--Button Content Start-->}*/}
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Previous}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Previous
                            </Button>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Button
                                onClick={Continue}
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                            >
                                Next
                            </Button>
                        </Grid>
                    </Grid>
                    {/*{!--Button Content End--}*/}
                </form>
            </div>
        </Container>
    </>
}

export default WorkingCapitalDetails;