import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import {BASE_API_URL} from "../config";
 

const RecommendationList = () => {
	console.log("Fetching recommendation mapping");
     
     const [loading, setLoading] = useState(false);
     const [groups, setGroups] = useState([]);
     const dataFetchedRef = useRef(false);
  
	useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let apiResponse = fetch(`${BASE_API_URL}/api/recommendation/recommendationsbyservice`, {
			method: 'GET',
			credentials: 'include'
		})
			.then(response => response.json())
			.then(data => {
				setGroups(data);
				setLoading(false);
			}).catch(function(error) {
				console.log("Error :" + error); 
			})

		console.log(apiResponse);

	}, []);
	
	if (loading) {
    return <p>Loading...</p>;
  }
  
  const groupList = groups.map(group => {
	
	
	 return <tr key={group.tmServiceId}>
		       <td style={{whiteSpace: 'nowrap'}}>{group.tmServiceName} </td>     
		       <td style={{whiteSpace: 'nowrap'}}>{group.tmServices} </td>  
		       <td>
			        <ButtonGroup>
			          <Button size="sm" color="primary" tag={Link} to={"/recommendation/edit/" + group.tmServiceId}>Edit</Button> &nbsp;&nbsp;
			          <Button size="sm" color="primary" tag={Link} to={"/recommendation/add/" + group.tmServiceId}>Add Recommendation</Button> &nbsp;&nbsp;
			          
			        </ButtonGroup>
			    </td>
		    </tr>
	 
	 
  });
 
  
  
  return (
    <div>
      <AppNavbar/>
      <Container fluid> 
      
      	<div> 
      		 <Table className="mt-4">
		          <thead>
		          <tr>
		            <th width="15%">TM Service Name</th>
		            <th width="60%">Mapped Recommendations</th>
		            <th width="25%">Actions</th>
		          </tr>
		          </thead>
		          <tbody>
		          	{groupList}
		          </tbody>
	        </Table> 
       	</div>
      </Container>
      </div>
     
  );
  
  }
 
export default RecommendationList;
