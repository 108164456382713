import React from 'react'
import { Container, Typography, Grid, TextField, Button, FormControlLabel, Checkbox, Radio, RadioGroup } from '@material-ui/core'




const PDetails = ({ nextStep, handleChange, values }) => {
  
  // for continue event listener
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  return (
	
	 
	
    <Container  component="main" maxWidth="lg">
      <div>
        <Typography  component="h5" variant="h5">
          Generate Presentation - Presentation Details
        </Typography>
        <form>
        <br/>
        <Grid item xs={12}>
              <TextField 
                placeholder="PresentationName"
                label="Presentation Name"
                onChange={handleChange('pname')}
                defaultValue={values.pname}
                autoComplete="PresentationName"
                fullWidth
              />
            </Grid>
            <br/>
            <p>Presentation Type:</p>
            <Grid item xs={12}>
				   <RadioGroup
   						 aria-labelledby="demo-radio-buttons-group-label"    					 
    					name="radio-buttons-group" > 
				    <Grid item xs={12} >
						  <FormControlLabel id='internal' value="internal"
							  control={<Radio onChange={handleChange('isinternalview')} />} label="Internal Presentation" />
					  </Grid>
					  <Grid item xs={12}>
						  <FormControlLabel id='external' value="external"
							  control={<Radio onChange={handleChange('isexternalview')} />} label="External Presentation" />
					  </Grid>
					  </RadioGroup>
				  </Grid>
				
        <br/>
         <Grid container spacing={2}> 
            <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Continue }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
    
  )
}

export default PDetails