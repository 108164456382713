import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button, ButtonGroup, Table,  } from '@material-ui/core'
import { Form, FormGroup } from 'reactstrap';
import { useParams,Link, useNavigate } from 'react-router-dom';
import AppNavbar from './AppNavbar';
import {BASE_API_URL} from "../config";
 
let synonyms = undefined;
const SynonymAdd = () => {
	
	const initialFormState = {
    Synonyms: ''
   
  };
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [group, setGroup] = useState(initialFormState);
	const { id, name, aname } = useParams();
	const history = useNavigate();
	let sanitizedName = name.replaceAll("-", " ");
	const navigate = useNavigate();
	
	 
	  useEffect(() => {
    setLoading(true);
     if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/synomymsbytmid/`+id, {
		        method: 'GET',
		        //   cache: 'no-cache',
                // mode: 'no-cors',
                // redirect: 'follow'
		credentials: 'include'
		       
		    })    
      .then(response => response.json())
      .then(data => { 
        synonyms = data.synonyms;
        console.log("synonyms :" + synonyms);
        setLoading(false);
      }).catch(function(error){
           console.log("Error :" + error); 
         })
      
      
     
  }, []);
  
      
	
	if (loading) {
    return <p>Loading...</p>;
  }
  
     const handleChange = (event) => {
    const { value } = event.target
    setGroup(value);
	console.log("Value :" + group);
     
  }

  
  const renderExistingSynonyms = <p style={{whiteSpace: 'unset'}}>{synonyms}</p>;
  
  const handleSubmit = async (event) => {
    event.preventDefault(); 
    let response =  fetch( BASE_API_URL+'/api/synomyms', {
		        method: 'POST',	
		        headers: {
                	'Accept': 'application/json, text/plain',
                	'Content-Type': 'application/json;charset=UTF-8'
            	},
				credentials: 'include',
		        body: JSON.stringify({
                name: aname,
                synonyms :  group
            }),
		    });
		    
		    console.log(response.status);
		    
		   window.location.reload();
    
    }
  const handleCancel = async (event) => {
    event.preventDefault(); 
    navigate('/bankers/pmapping');
    
    }

  return (
	  <div>
		  <AppNavbar />
		  <Container component="main" >
			  <div>
				  <Typography component="h1" variant="h6">
					  Please enter comma seperated synonyms for {sanitizedName}
				  </Typography>
				  <br />
				  <Form onSubmit={handleSubmit}>
					  <Grid item xs={12} sm={6}>
						  <TextField
							  placeholder="Synonyms"
							  label="Synonyms"
							  onChange={handleChange}
							  // defaultValue={ }
							  // variant="outlined"
							  autoComplete="Synonyms"
							  fullWidth
						  />
					  </Grid>
					  <br/>
					  <FormGroup>
						  <Button color="primary" type="submit">Save</Button>{' '}
						  <Button color="secondary" onClick={handleCancel}>Cancel</Button>
						  <Button color="secondary" onClick={handleCancel}>Back</Button>
					  </FormGroup>
				  </Form>

				  <br />

				  <Typography component="h1" variant="h6">
					  Configured Synonyms
				  </Typography>

				  {renderExistingSynonyms}
			  </div>
		  </Container>

	  </div>
  )
};

export default SynonymAdd;
