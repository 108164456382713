import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button, ButtonGroup, Table } from '@material-ui/core'
import { useParams, useNavigate } from 'react-router-dom';
import AppNavbar from './AppNavbar';
import {BASE_API_URL} from "../config";
 

const RecommendationAdd = () => {
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [groups, setGroups] = useState([]);
	const { id } = useParams();
	const history = useNavigate();
	
	useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let apiResponse = fetch(`${BASE_API_URL}/api/tm-services/exclude?id=`+id, {
			method: 'GET',
			// redirect: "manual",
			// cache: 'no-cache'
			credentials:'include'
		})
			.then(response => response.json())
			.then(data => {
				setGroups(data);
				setLoading(false);
			}).catch(function(error) {
				console.log("Error :" + error);				
			})

		console.log(apiResponse);

	}, []);
	
	if (loading) {
    return <p>Loading...</p>;
  }
  
  const addService = (group) => {     
    console.log("id : " + id + "--> Group :", group);
     
     
    let response =  fetch(`${BASE_API_URL}/api/recommendation/`+id+'/recommendations', {
		        method: 'POST',
				credentials: 'include',
		        headers: {
                'Accept': 'application/json, text/plain',
                'Content-Type': 'application/json;charset=UTF-8'
            },	       
		        body: JSON.stringify({
                name: group.name,
                TMService_id :  id
            }),
		    });
		    
		    console.log(response);
		    
		    history('/recommend/mapping');
		   
		    
	 
    
  }
  
  
    let rowcount = 0;
   const groupList = groups.map(group => {
	
	 let clr = "#d1e0e6";
	  rowcount++;
    if (rowcount % 2 === 0) {
     clr = "white"
     }
	 
	  return <tr key={group.id} bgcolor={clr}>
		      <td style={{whiteSpace: 'nowrap'}}>{group.name} </td> 
		      <td>
        	<Button 
                onClick={() => addService(group)}              
                
                variant="contained"
                color="secondary"
              >Select
              </Button>
      </td>
		    </tr>
  });


  return (
	<div>
      <AppNavbar/>
    	<Container  component="main" >
	      <div> 
	        <Typography  component="h1" variant="h6"> 
	          Select the recommendations from the standard TM Services list
	        </Typography>  
	        
	        <Table className="mt-4">
	          <thead>
	          <tr>
	            <th width="80%">TM Service Name</th>	            
	            <th width="20%">Action</th>
	          </tr>    
	          </thead>
	          <tbody>
				  <tr>
					  <th width="10%"><hr /></th>
					  <th width="10%"><hr /></th>					  
				  </tr>    
	        	 {groupList}
	          </tbody>
       	     </Table>
        
        <br/>
	        
	        
	       </div>
   		</Container>
      
    </div>
  )
};

export default RecommendationAdd;
