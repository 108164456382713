import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import {BASE_API_URL} from "../config";
 

const ProcessedFilesList = () => {
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [groups, setGroups] = useState([]);
 
	
	console.log("Fetching parser configuration service mapping");
	
	useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let apiResponse = fetch(`${BASE_API_URL}/api/fetchProcessedfiles`, {
			method: 'GET',
			credentials:'include'
		})
			.then(response => response.json())
			.then(data => {
				setGroups(data);
				setLoading(false);
			}).catch(function(error) {
				console.log("Error :" + error); 
			})

		console.log(apiResponse);

	}, []);
	 
	if (loading) {
    return <p>Loading...</p>;
  }
  
   const groupList = groups.map(group => {  
	 return <tr key={group.tmServiceId}>
		       <td style={{whiteSpace: 'unset'}}>{group.fileName} </td>     
		       <td style={{whiteSpace: 'unset'}}><a href={group.url}>{group.url}</a> </td>  
		       <td>
			        <ButtonGroup>
			         <Button size="sm" color="danger" onClick={() => remove(group.fileName)}>Delete</Button>&nbsp;&nbsp;	             
			        </ButtonGroup>
			    </td>
		    </tr>
	 
	 
  });
  
  
   const remove = async (name) => {
    console.log("Delete..." + name);
     let response = await fetch(BASE_API_URL + '/api/processedfiles/'+name, {
		       method: 'DELETE',
      			credentials: 'include'
    });
    
     if(response.status == 403){
				alert("Not authorized to delete a file!!!!");
			}
    
    
     if(response.status === 204){			
			 alert("Deleted the file "+ name + " sucessfully");   
			  window.location.reload();   		 
		}
    
    if(response.status != 200){ 
	           	console.log("Error: "+ response.message);
	 }	
    
  }
    
	 
	   


  return (
	<div>
      <AppNavbar/>
    	<Container  component="main" >
	      <div> 
	        <p>  <b>Parsing History</b> </p> 
	        
	        <Table className="mt-4">
		          <thead>
		          <tr>
		            <th width="20%">File Name</th>
		            <th width="60%">URL</th>
		            <th width="20%">Actions</th>
		          </tr>
		          </thead>
		          <tbody>
		          	 	{groupList}
		          </tbody>
	        </Table> 
	        
	       </div>
   		</Container>
      
    </div>
  )
};

export default ProcessedFilesList;
