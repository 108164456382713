import React from 'react'
// import { makeStyles } from '@material-ui/core/styles';
import { Container, Typography, Grid, TextField, Button, ImageList, ImageListItem, ImageListItemBar } from '@material-ui/core'




const RBDetails = ({ prevStep, nextStep, handleChange, values }) => {
  
  // for continue event listener
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }
  
    const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return (
	
	 
	
    <Container  component="main" maxWidth="xs">
      <div>
        <Typography  component="h1" variant="h5">
          Generate Presentation - Relationship Manager Details
        </Typography>
        <form>
        	<br />
        	 <Grid container spacing={2}>
        	{/* First Name */}
              <Grid item xs={12} sm={6}>
                First Name
                <input type="text"  value={values.rbfirstname} readOnly={true}/>
              </Grid>
              <br />
              {/* Last Name */}
              <Grid item xs={12} sm={6}>
              Last Name
                <input type="text"  value={values.rblastname} readOnly={true}/>
                
              </Grid>
              <br />
           
              {/* email address */}
              <Grid item xs={12}>
                 Email <br/>
                <input type="text"  width="100%" value={values.rbemail} readOnly={true}/>
                 
              </Grid>
              <br />
              {/* Title */}
              <Grid item xs={12} sm={6}>
                <TextField 
                  placeholder="Title"
                  label="Title"
                  onChange={handleChange('rbtitle')}
                  defaultValue={values.rbtitle}
                  // variant="outlined"
                  autoComplete="Title"
                  fullWidth
                />
              </Grid>
              <br />
              {/* Department */}
              <Grid item xs={12} sm={6}>
                <TextField 
                  placeholder="Department"
                  label="Department"
                  onChange={handleChange('rbdepartment')}
                  defaultValue={values.rbdepartment}
                  // variant="outlined"
                  autoComplete="Department"
                  fullWidth                  
                />
              </Grid>
               <br />
              
              {/* Mobile */}
              <Grid item xs={12} sm={6}>
                <TextField 
                  placeholder="Mobile"
                  label="Mobile"
                  onChange={handleChange('rbmobile')}
                  defaultValue={values.rbmobile}
                  // variant="outlined"
                  autoComplete="Mobile"
                  fullWidth                  
                />
              </Grid>
          </Grid>
             
          <br />
          <Grid container spacing={2}>
           <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Previous }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Continue }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
             </Grid>
        </form>
        <img src="/Introslide.jpg"  width="100%"/>
        
      </div>
    </Container>
    
  )
}

export default RBDetails