import React from 'react'
import { Container, Typography, Grid, TextField, Button, FormControlLabel, Checkbox  } from '@material-ui/core'

const StmtSelect = ({ prevStep, nextStep, handleChange, values }) => {
  
 const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  return ( 
	
    <Container  component="main" width="100%">
      <div>
        <Typography  component="h1" variant="h5" width="100%">
          Generate Presentation - Statement type 
          <br/>
          Phase 1 : Analysis supported by default hence select option disabled
        </Typography>
        <form>
        <br/>
				  <Grid container spacing={2}>
					  {/* first name */}
					  <Grid item xs={12} sm={12}>					  
					  <FormControlLabel control={<Checkbox disabled  defaultChecked />} label="Analysys Statement" /> 
					  </Grid>
				  </Grid>
              <br/>
              <Grid container spacing={2}>
		        	<Grid item xs={12} sm={6}>
		              <Button 
		                onClick={ Previous }
		                type="submit"
		                fullWidth
		                variant="contained"
		                color="primary"
		              >
		                Previous
		              </Button>
		            </Grid>
		            <Grid item xs={12} sm={6}>
		              <Button 
		                onClick={ Continue }
		                type="submit"
		                fullWidth
		                variant="contained"
		                color="primary"
		              >
		                Next
		              </Button>
		            </Grid>
        	 </Grid>
        </form>
      </div>
    </Container>
  )
}

export default StmtSelect