import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { Button, ButtonGroup, Container, Table} from 'reactstrap';
import { useCookies } from 'react-cookie';
import {BASE_API_URL} from "../config";

const TMServiceEdit = () => {
	
	 const [loading, setLoading] = useState(false);
	 const dataFetchedRef = useRef(false);
 	 const [groups, setGroups] = useState([]);
 	 const [cookies] = useCookies(['XSRF-TOKEN']);
	
	useEffect(() => {
    setLoading(true);
      if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/tm-services`, {
		        method: 'GET',
		          /*cache: 'no-cache',
                mode: 'no-cors',
                redirect: 'follow'*/
		credentials: 'include'
		       
		    })    
      .then(response => response.json())
      .then(data => {
        setGroups(data);
        setLoading(false);
      }).catch(function(error){
           console.log("Error :" + error); 
         })  
  }, []);
  
  if (loading) {
    return <p>Loading...</p>;
  }
  
   const activate = async (id) => {
	 console.log("Need to activate :"+ id);
	 await fetch(`${BASE_API_URL}/api/tm-services/activate/`+id, {
      method: 'PUT',
      headers: {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include'
    }).then(() => { 
       window.location.reload();
    });
  }
  
   const groupList = groups.map(group => {
	 
	if(group.active==='N'){
		
		 return <tr key={group.id}>
		      <td style={{whiteSpace: 'nowrap'}}>{group.name} </td>   
		       <td style={{whiteSpace: 'nowrap'}}> 
		       <Button size="sm" color="danger" onClick={() => activate(group.id)}>Activate</Button>
		       </td>
		         
		     
		    </tr>
			
	}
  });
  
	
	 return (
		<div>
		      <AppNavbar/>
		     <Container fluid> 
			        <h3>Activate Recomendations</h3>
			        <Table className="mt-4">
			          <thead>
			          <tr>
			            <th width="20%">Recomendation Name</th>
			           	<th width="10%">Actions</th>
			          </tr>
			          </thead>
			          <tbody>
			          {groupList}
			          </tbody>
			        </Table>
			 </Container>
		</div>
     )

};

export default TMServiceEdit;