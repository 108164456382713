import React, {  useEffect, useState, useRef } from 'react';
import '../App.css';
import { Collapse, Nav, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Link, useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import axios from "axios";
import {BASE_API_URL} from "../config";


 // let count = 0;
 // let auth = false;
 // let isAdminUser = false;
 // let isRMUser = false;
 // let isEveryOneElse = false;
 
const AppNavbar = () => {

  const [isOpen, setIsOpen] = useState(false);  
  const [cookies] = useCookies(['XSRF-TOKEN']);  
  const [loading, setLoading] = useState(false);  
  const [authenticated, setAuthenticated] = useState(false);
  const [user, setUser] = useState(undefined);
  const [auth, setAuth] = useState(false);
  const [isAdminUser, setAdminUser] = useState(false);
  const [isRMUser, setRMUser] = useState(false);
  const [isEveryOneElse, setEveryOneElse] = useState(false);
  const [count, setCount] = useState(0);
   let dataFetchedRef = useRef(false);
   let authenticatedUSer = null;
   const history = useNavigate();
  
  
   useEffect(() => {
	 
    setLoading(true); 
     if (dataFetchedRef.current) return;
     else {
	    dataFetchedRef.current = true;
	    setCount(count + 1);
	}
	
	 
	
	// if(count===1) {
        console.log(count, "COUNT")
        // axios.get('${BASE_API_URL}/api/user', {withCredentials: true})
        //     .then(response => {
        //         try {
        //             console.log(response, "RESPONSE.....");
        //         }catch (e) {
        //             console.log(e, "error in response");
        //         }
        //     }).catch(err => {
        //     console.log(err, "ERROR USER API");
        //     if(err.response) {
        //         console.log(err.response);
        //     }
        // });
		    fetch(`${BASE_API_URL}/api/user`, { credentials: 'include'})
		      .then(response => response.text())
		      .then(body => {
		        if (body === '') {
		          setAuthenticated(false);
		          setAuth(false);
		          console.log("User not authenticated, redirecting the user to homepage");
					history('/');
		        } else {		          
		          setUser(JSON.parse(body));
		          authenticatedUSer = JSON.parse(body);
                    // console.log(authenticatedUSer, "USERSSS");
		          setAuthenticated(true);
		          if(authenticatedUSer.groups.includes('admins')){						
						setAdminUser(true);
					}
				  if(authenticatedUSer.groups.includes('rusers')){						
						setRMUser(true);
				   }
				   if(authenticatedUSer.groups.includes('Everyone')){						
						setEveryOneElse(true);
				   }
                    setAuth(true);
		        }
		        setLoading(false); 
		         dataFetchedRef.current = true;
		         
		      });
      
      // }
  }, [])
  
  
  const logout = () => {
    fetch(`${BASE_API_URL}/api/logout`, {
      method: 'POST', credentials: 'include',
      headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] }
    })
      .then(res => res.json())
      .then(response => {
        window.location.href = `${response.logoutUrl}?id_token_hint=${response.idToken}`
          + `&post_logout_redirect_uri=${window.location.origin}`;
      });
  }
   
     const parserMapping = isAdminUser ?  <NavItem>
	              <NavLink href="/bankers/pmapping">Parser Configuration</NavLink>
          </NavItem>   : 
       <Collapse isOpen={isOpen} navbar>  
           </Collapse>;
    
    
    
    const recomendations = isAdminUser ?  <NavItem>
	              <NavLink href="/bankers/tmservice">Manage Recommendations</NavLink>
          </NavItem>   : 
       <Collapse isOpen={isOpen} navbar>  
           </Collapse>;
           
      const bankers  = isAdminUser ?    <NavItem>
            <NavLink href="/bankers">Manage Bankers</NavLink>
          </NavItem>   : 
       <Collapse isOpen={isOpen} navbar>  
           </Collapse>;    
           
        const rm  = /*isRMUser*/ isAdminUser ?     <NavItem>
            <NavLink href="/bankers/aastmt">Generate Powerpoint</NavLink>
          </NavItem>
            : 
       <Collapse isOpen={isOpen} navbar>  
           </Collapse>;    
           
             const processedFilesList  = isEveryOneElse ?     <NavItem>
            <NavLink href="/bankers/pfiles">Run History</NavLink>
          </NavItem>
            : 
       <Collapse isOpen={isOpen} navbar>  
           </Collapse>;    
    
    const button = auth ?
     <Collapse isOpen={isOpen} navbar>
        <Nav className="justify-content-end" style={{width: "100%"}} navbar>
			  {rm}
			  {processedFilesList}
        	  {bankers}
              {recomendations}
              {parserMapping}
          <NavItem>
            <NavLink onClick={logout}>Logout</NavLink>
          </NavItem>
        </Nav>
      </Collapse>: 
       <Collapse isOpen={isOpen} navbar>
           This
       </Collapse>;

  return (
    <Navbar color="dark" dark expand="md">
      <NavbarBrand tag={Link} to="/">Lanica Solutions</NavbarBrand>
      <NavbarToggler onClick={() => { setIsOpen(!isOpen) }}/>
      {auth ?
          <Collapse isOpen={isOpen} navbar>
              <Nav className="justify-content-end" style={{width: "100%"}} navbar>
                  {/*isRMUser*/ isAdminUser ?     <NavItem>
                          <NavLink href="/bankers/aastmt">Generate Powerpoint</NavLink>
                      </NavItem>
                      :
                      <Collapse isOpen={isOpen} navbar>
                      </Collapse>}
                  {isEveryOneElse ?     <NavItem>
                          <NavLink href="/bankers/pfiles">Run History</NavLink>
                      </NavItem>
                      :
                      <Collapse isOpen={isOpen} navbar>
                      </Collapse>}
                  {isAdminUser ?    <NavItem>
                          <NavLink href="/bankers">Manage Bankers</NavLink>
                      </NavItem>   :
                      <Collapse isOpen={isOpen} navbar>
                      </Collapse>}
                  {isAdminUser ?  <NavItem>
                          <NavLink href="/bankers/tmservice">Manage Recommendations</NavLink>
                      </NavItem>   :
                      <Collapse isOpen={isOpen} navbar>
                      </Collapse>}
                  {isAdminUser ?  <NavItem>
                          <NavLink href="/bankers/pmapping">Parser Configuration</NavLink>
                      </NavItem>   :
                      <Collapse isOpen={isOpen} navbar>
                      </Collapse>}
                  <NavItem>
                      <NavLink href={"#"} onClick={logout}>Logout</NavLink>
                  </NavItem>
              </Nav>
          </Collapse>:
          <Collapse isOpen={isOpen} navbar>
              This
          </Collapse>}
     
    </Navbar>
  );
};

export default AppNavbar;
