import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';
import AAQuestionnaire from '../components/AAstmtCongQues';
import {BASE_API_URL} from "../config";

let uploadedFileName;
let uploadedFileDownloadURL;
let uploadedFileType;
let processedFileName;
let mappingFileName;
let mappingFileURL;
let processedFileURL;

let user = null;
const AAstmt = () => {
	
	const [authenticated, setAuthenticated] = useState(false);
	const [file, setFile] = useState(null);
	
	
	const [file1, setFile1] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useNavigate();
	 
	let dataFetchedRef = useRef(false);
	
	  
	useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
			.then(response => response.text())
			.then(body => {
				if (body === '') {
					setAuthenticated(false);
					console.log("User not authenticated, redirecting the user to homepage");
					history('/');
				} else {
					user = body;
					//setUser(body);
					setAuthenticated(true);
				}
				setLoading(false);
			});
	}, [])

  	  
	 return (<div>
	 <AppNavbar/>
	  <Container > 
         
         <div className="upload-container" width="100%">
             <AAQuestionnaire tuser={JSON.parse(user)}/>
        </div>
        
       <br/>
	<br/>
	 
              
         </Container>    
	
	 </div>)
	 
};

export default AAstmt;