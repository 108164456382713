import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {  Container } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { useCookies } from 'react-cookie';
import {BASE_API_URL} from "../config";

const BankerActivate = () => {	
	
	const initialFormState = {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    bankingVetical: '',
    branchName: '',
    department: '',
    primaryContactNumber: '',
    primaryContactType: '',
    secondaryContactNumber: '',
    secondaryContactType: '',
    active: 'P' 
  };

  const [group, setGroup] = useState(initialFormState);  
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [userinfo, setUserinfo] = useState(false);
  const dataFetchedRef = useRef(false);
  const [cookies] = useCookies(['XSRF-TOKEN']);  
  var loader = document.querySelector('#loader');
  



  useEffect(() => {
	setLoading(true);
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
      
    if (id !== 'new') {
      fetch(`${BASE_API_URL}/api/bankers/${id}`, {credentials:'include'})
        .then(response => response.json())
        .then(data => setGroup(data));
    }
    
   
   
  }, [id, setGroup]);
  
  
  
	useEffect(() => {
		if (group === '') {
			setUserinfo(false);
			console.log("Failed to fetch user data");
		} else {
			setUserinfo(true);

			if (group.firstName === '') {
				console.log("Name :" + group.firstName);
			} else {

				
				fetch(BASE_API_URL + '/api/idp/createUser', {
					method: 'POST',
					headers: {
						'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
						'Accept': 'application/json',
						'Content-Type': 'application/json'
					},
					credentials: 'include',
					body: JSON.stringify(group)					
				})  
					.then(res => {						
						if(res.status == 403){
							alert("Not Authorized to activate a banker!!!!");
						} else if (res.status != 200) {
							console.log("Status code:" + res.status);
							loader.innerHTML =  "<h2>System cannot process request, please contact support to resolve the issue</h2>";
						} else if (res.status === 200){ 
							console.log("Status code:" + res.status);
							loader.innerHTML = "<h2>Sucessfully submited activation request to the corporate identity provider.  Please request the user to follow the email instructions received from the corporate identity provider</h2>";

							//Start of patch operation
							 fetch(BASE_API_URL + '/api/bankers/'+id, {
								method: 'PATCH',
								headers: {
									'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
									'Accept': 'application/json-patch+json',
									'Content-Type': 'application/json-patch+json'
								},
								 credentials: 'include',
								body: JSON.stringify([{
									op: 'replace',
									path: '/active',
									value: 'P'
								}])
							})  
							
							//end of patch operation
								.then(res => {
									if (res.status != 200) {									
										console.log("Activation Status update issue:" + res.status);										
									} else if (res.status === 200) {
										console.log("Activation Status updated sucessfully" + res.status);
										
									}
									return res.json();

								})
						}
						return res.json();
						
					})
					
					
				
				
				//end of fetch
				
			}

		}
       
  });  
 
  
  return (<div>
      <AppNavbar/>
      <Container> 
         <div  id='loader'>
         Processing request, please wait!!!
         </div>
      </Container>
    </div>
  )
};

export default BankerActivate;
