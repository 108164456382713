import React from 'react'
import { Container, Grid, List, ListItem, ListItemText, Button, Table, Typography, ImageListItem } from '@material-ui/core'

const Confirmation = ({ prevStep, nextStep, values }) => {
	const { rbemail, rbtitle, rbdepartment, rbfirstname, rblastname, rbmobile, cemail, ctitle, cdepartment, 
			cfirstname, clastname, cmobile, clogo, bankers, ispieview, issmartview, isstatsview,
		isrecomendedview, isaccountview, issmartsrcvview, issmartartview, statementtype,
			pname,isinternalview, isexternalview, graphItems } = values
	
	
	
	const Continue = e => {
		e.preventDefault();
		nextStep();
	}

	const Previous = e => {
		e.preventDefault();
		prevStep();
	}
	
	
	 const renderInternalView = <h6>{isinternalview ? <p>Internal view presentation</p> : ''}</h6>;
	 
	 const renderExternalView =	<h6>{isexternalview ? <p>External view presentation</p> : ''}</h6>;
	
	 const renderPieView = <h2>{ispieview ? <img
								  src="/PieChartSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;
							  
     const renderSmartView = <h2>{issmartview ? <img
								  src="/SmartChartSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;	
							  
     const renderStatstView = <h2>{isstatsview ? <img
								  src="/SummaryViewSample.JPG"								  
								  alt="Church"
								  loading="lazy"
								  width="200"
								  height="200"
							  /> : ''}</h2>;
	const renderRecommendView = <h2>{isrecomendedview ? <img
		src="/RecommendationViewSample.JPG"
		alt="Church"
		loading="lazy"
		width="200"
		height="200"
	/> : ''}</h2>;

	const renderSmartProtectView = <h2>{issmartartview ? <img
		src="/SmartProtectViewSample.JPG"
		alt="Church"
		loading="lazy"
		width="200"
		height="200"
	/> : ''}</h2>;

	const renderSmartServiceView = <h2>{issmartsrcvview ? <img
		src="/SmartServiceViewSample.JPG"
		alt="Church"
		loading="lazy"
		width="200"
		height="200"
	/> : ''}</h2>;

	const renderAccountView = <h2>{isaccountview ? <img
		src="/AccountViewSample.JPG"
		alt="Church"
		loading="lazy"
		width="200"
		height="200"
	/> : ''}</h2>;





	const groupList = Array.from(bankers).map((banker, i) => {
	 
    return (
	<tr key={i}>
      <td>{banker.firstName} {banker.lastName}</td>     
      <td>{banker.email}</td> 
      <td>{banker.title}</td> 
      <td>{banker.department}</td> 
    </tr>
    )
    
    	});
    	
   
    
    
  

	return (
		
		
		<Container component="main" maxWidth="lg">
			<div>
			<Typography  component="h1" variant="h5">
			        Presentation Name:
			      </Typography>
			<Grid item xs={12}>
              <List>
							<ListItem>
								<ListItemText primary="" secondary={pname} />
							</ListItem>
							 
            </List>
            </Grid>
			<hr/>
			<Typography  component="h1" variant="h5">
			         Type of presentation selected:
			      </Typography>
			<Grid item xs={12}>
              <List>
							{renderInternalView}
							{renderExternalView}
            </List>
            </Grid>
            <hr/>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6}>
						<List>
							<ListItem>
								<ListItemText primary="Banker First Name" secondary={rbfirstname} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Banker Last Name" secondary={rblastname} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Banker Email" secondary={rbemail} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Banker Title" secondary={rbtitle} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Banker Department" secondary={rbdepartment} />
							</ListItem>
							
							<ListItem>
								<ListItemText primary="Banker Mobile" secondary={rbmobile} />
							</ListItem>
							
						</List>
					</Grid>
					<Grid item xs={12} sm={6}>
						<List>
							 <ListItem>
							 	<ListItemText primary="Client Logo" />
								<img src={clogo} width="100" height="50"/>
							</ListItem> 
							<ListItem>
								<ListItemText primary="Client First Name" secondary={cfirstname} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Client Last Name" secondary={clastname} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Client Email" secondary={cemail} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Client Title" secondary={ctitle} />
							</ListItem>
							<ListItem>
								<ListItemText primary="Client Department" secondary={cdepartment} />
							</ListItem>
							
							<ListItem>
								<ListItemText primary="Client Mobile" secondary={cmobile} />
							</ListItem> 
							
							 
						</List>
					</Grid>
				</Grid>
				<hr/>
				<Typography  component="h1" variant="h5">
			         Types of views selected:
			      </Typography>
				
				<Grid container spacing={2}>
					{
						graphItems.map(graph => {
							if(graph.isSelected) {
								return <Grid item xs={12} sm={4}>
											<h2>
												<img
													src={`/${graph.image}`}
													alt={graph.alt}
													loading="lazy"
													width="200"
													height="200"
												/>
											</h2>
										</Grid>
							} else {
								return null;
							}
						})
					}
				</Grid>
				<hr/>
				 <Typography  component="h1" variant="h5">
			          Bankers Selected:
			      </Typography>
				<Table >
          <thead>
          <tr>
            <th width="30%">Name</th>
            <th width="40%">Email</th>            
            <th width="20%">Title</th>
            <th width="10%">Department</th>            
          </tr>    
          </thead>
          <tbody> 
				  	{groupList} 
			   </tbody>
			   <tbody>
			  
			   </tbody>
        </Table>
		
				
				<Grid container>
					<Grid item xs={12} >
					 
					
					 
				
					</Grid>
				</Grid>

				<br />
				<Grid container spacing={2}>
					<Grid item xs={12} sm={4}>
						<Button
							onClick={Previous}
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
						>
							Previous
						</Button>
					</Grid>
					<Grid item xs={12} sm={8}>
						<Button
							onClick={Continue}
							type="submit"
							fullWidth
							variant="contained"
							color="primary"
						>
							Confirm & Continue
						</Button>
					</Grid>
				</Grid>
			</div>
		</Container>
	)
}

export default Confirmation