import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import {BASE_API_URL} from "../config";
 

const ParserConfig = () => {
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [groups, setGroups] = useState([]);
 
	
	console.log("Fetching parser configuration service mapping");
	
	useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let apiResponse = fetch(`${BASE_API_URL}/api/synomymsgroupbytmname`, {
			method: 'GET',
			credentials:'include'
		})
			.then(response => response.json())
			.then(data => {
				setGroups(data);
				setLoading(false);
			}).catch(function(error) {
				console.log("Error :" + error); 
			})

		console.log(apiResponse);

	}, []);
	 
	if (loading) {
    return <p>Loading...</p>;
  }
  
   const groupList = groups.map(group => {
	let sanitizedName = group.tmServiceName.replaceAll("/", "-").replaceAll(" ", "-").toLowerCase();
 
	
	 return <tr key={group.tmServiceId}>
		       <td style={{whiteSpace: 'unset'}}>{group.tmServiceName} </td>     
		       <td style={{whiteSpace: 'unset'}}>{group.synonyms} </td>  
		       <td>
			        <ButtonGroup>
			          <Button size="sm" color="primary" tag={Link} to={"/synonym/edit/" + group.tmServiceId+"/"+sanitizedName}>Edit</Button> &nbsp;&nbsp;
			          <Button size="sm" color="primary" tag={Link} to={"/synonym/add/" + group.tmServiceId+"/"+sanitizedName+"/"+group.tmServiceName}>Add Synonym</Button> &nbsp;&nbsp;			          
			        </ButtonGroup>
			    </td>
		    </tr>
	 
	 
  });
  
  
  
    
	 
	   


  return (
	<div>
      <AppNavbar/>
    	<Container  component="main" >
	      <div> 
	        <p>  <b>Parser Configuration Settings</b> </p> 
	        
	        <Table className="mt-4">
		          <thead>
		          <tr>
		            <th width="20%">TM Service Name</th>
		            <th width="60%">Mapped Synonyms</th>
		            <th width="20%">Actions</th>
		          </tr>
		          </thead>
		          <tbody>
		          	 	{groupList}
		          </tbody>
	        </Table> 
	        
	       </div>
   		</Container>
      
    </div>
  )
};

export default ParserConfig;
