import React, {Component} from 'react'
import StmtSelect from './StmtSelect'
import RBDetails from './RBDetails'
import CTLDetails from './CLTDetails'
import Confirmation from './Confirmation'
import ChooseBankers from './ChooseBankers'
import ChooseViewPoints from './ChooseViewPoints'
import Success from './Success'
import AAstmtUpload from '../pages/AAstmtUpload';
import PDetails from './PDetails'
import WorkingCapitalDetails from "./WorkingCapitalDetails";


export default class AAQuestionnaire extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            statementtype: 'AA',
            rbemail: '',
            rbtitle: '',
            rbdepartment: '',
            rbfirstname: '',
            rblastname: '',
            rbmobile: '',
            cemail: '',
            ctitle: '',
            cdepartment: '',
            cfirstname: '',
            clastname: '',
            cmobile: '',
            clogo: '',
            // Working Capital
            workingCapital: true,
            company: '',
            totalSaleRevenue: '',
            cogs: '',
            accountReceivables: '',
            accountPayables: '',
            loanIntRate: '',
            investmentIntRate: '',
            inventory: '',
            // Working Capital
            // Choose Graphical / Chart View
            ispieview: false,
            issmartview: false,
            isstatsview: false,
            isrecomendedview: false,
            issmartartview: false,
            issmartsrcvview: false,
            isaccountview: false,
            workingCapitalWithInventory: false,
            workingCapitalWithoutInventory: false,
            workingCapitalCCCDateWithInventory: false,
            workingCapitalCCCDateWithoutInventory: false,
            workingCapitalCCCAmountWithInventory: false,
            workingCapitalCCCAmountWithoutInventory: false,
            // End of Graphical and Chart View
            bankers: new Set(),
            selectedbankers: [],
            pname: 'Treasury and Liquidity Management Presentation',
            isinternalview: false,
            isexternalview: false
        }
    }

    // go back to previous step
    prevStep = () => {
        const {step} = this.state;
        this.setState({step: step - 1});
    }

    // proceed to the next step
    nextStep = () => {
        const {step} = this.state;
        this.setState({step: step + 1});
    }

    handleSelectedBankers = (selectedbankers) => {
        console.log(selectedbankers);
        this.state.selectedbankers = selectedbankers;
    }


    handleChange = input => e => {
        const checkboxControllers = ['isstatsview', 'issmartview', 'ispieview', 'isrecomendedview', 'issmartartview',
            'issmartsrcvview', 'isaccountview', 'isinternalview', 'isexternalview',
        'workingCapitalWithInventory', 'workingCapitalCCCAmountWithInventory', 'workingCapitalCCCAmountWithoutInventory',
            'workingCapitalCCCDateWithInventory', 'workingCapitalCCCDateWithoutInventory', 'workingCapitalWithoutInventory'];
        // if(input==='isstatsview' || input==='issmartview' || input==='ispieview' || input==='isinternalview' || input==='isexternalview'){
        if (checkboxControllers.includes(input)) {
            let keyToFalse = '';
            const changedObject = {[input]: e.target.checked};
            if (input === 'isinternalview' && e.target.checked) {
                keyToFalse = 'isexternalview';
            }
            if (input === 'isexternalview' && e.target.checked) {
                keyToFalse = 'isinternalview';
            }
            if (keyToFalse) {
                // External or internal changed
                changedObject[keyToFalse] = false;
            }
            this.setState((prevState) => {
                return {
                    ...prevState,
                    ...changedObject
                }
            });
            // this.setState({ [input]: e.target.checked });
        } else {
            // Working capitals radio
            let value = e.target.value;
            if(input === 'workingCapital') {
                value = value === 'yes';
            }
            if(['totalSaleRevenue', 'cogs', 'accountReceivables', 'accountPayables', 'loanIntRate', 'investmentIntRate', 'inventory'].includes(input)) {
                // Check if the value is number
                if(isNaN(value)) {
                    return;
                }
            }
            this.setState({[input]: value});
        }
    }


    render() {

        let userInfo = this.props.tuser;
        if (userInfo != null) {
            this.state.rbfirstname = this.props.tuser.given_name;
            this.state.rblastname = this.props.tuser.family_name;
            this.state.rbemail = this.props.tuser.email;


            const {step} = this.state;
            const {rbemail, rbtitle, rbdepartment, rbfirstname, rblastname, rbmobile,
                cemail, ctitle, cdepartment, cfirstname, clastname, cmobile, clogo,
                workingCapital, company, totalSaleRevenue, cogs, accountReceivables, accountPayables,
                loanIntRate, investmentIntRate, inventory,
                bankers, ispieview, issmartview, isstatsview, isrecomendedview, isaccountview,
                issmartsrcvview, issmartartview,
                statementtype, selectedbankers, pname, isinternalview, isexternalview,
                workingCapitalCCCAmountWithInventory,
                workingCapitalCCCAmountWithoutInventory,
                workingCapitalCCCDateWithInventory,
                workingCapitalCCCDateWithoutInventory,
                workingCapitalWithoutInventory,
                workingCapitalWithInventory} = this.state;
            const values = {
                rbemail,
                rbtitle,
                rbdepartment,
                rbfirstname,
                rblastname,
                rbmobile,
                cemail,
                ctitle,
                cdepartment,
                cfirstname,
                clastname,
                cmobile,
                clogo,
                // Working capital
                workingCapital,
                company,
                totalSaleRevenue,
                cogs,
                accountReceivables,
                accountPayables,
                loanIntRate,
                investmentIntRate,
                inventory,
                // Working capital
                bankers,
                ispieview,
                issmartview,
                isstatsview,
                isrecomendedview, isaccountview, issmartsrcvview, issmartartview,
                statementtype,
                selectedbankers,
                pname,
                isinternalview,
                isexternalview,
                workingCapitalCCCAmountWithInventory,
                workingCapitalCCCAmountWithoutInventory,
                workingCapitalCCCDateWithInventory,
                workingCapitalCCCDateWithoutInventory,
                workingCapitalWithoutInventory,
                workingCapitalWithInventory,
                // Create an array of items of graph
                graphItems: [
                    {
                        image: "PieChartSample.JPG",
                        alt: "PieChartSample",
                        isSelected: ispieview
                    },

                    {
                        image: "SmartChartSample.JPG",
                        alt: "SmartChartSample",
                        isSelected: issmartview
                    },

                    {
                        image: "SummaryViewSample.JPG",
                        alt: "SummaryViewSample",
                        isSelected: isstatsview
                    },

                    {
                        image: "RecommendationViewSample.JPG",
                        alt: "RecommendationViewSample",
                        isSelected: isrecomendedview
                    },

                    {
                        image: "SmartProtectViewSample.JPG",
                        alt: "SmartProtectViewSample",
                        isSelected: issmartartview
                    },

                    {
                        image: "SmartServiceViewSample.JPG",
                        alt: "SmartServiceViewSample",
                        isSelected: issmartsrcvview
                    },

                    {
                        image: "AccountViewSample.JPG",
                        alt: "AccountViewSample",
                        isSelected: isaccountview
                    },
                    // Working capital
                    {
                        image: "WorkingCapitalCCCAmountWithInventory.JPG",
                        alt: "WorkingCapitalCCCAmount",
                        isSelected: workingCapitalCCCAmountWithInventory
                    },
                    {
                        image: "WorkingCapitalCCCAmountWithoutInventory.JPG",
                        alt: "WorkingCapitalCCCAmount",
                        isSelected: workingCapitalCCCAmountWithoutInventory
                    },
                    {
                        image: "WorkingCapitalCCCDateWithInventory.JPG",
                        alt: "WorkingCapitalCCCDate",
                        isSelected: workingCapitalCCCDateWithInventory
                    },
                    {
                        image: "WorkingCapitalCCCDateWithoutInventory.JPG",
                        alt: "WorkingCapitalCCCDate",
                        isSelected: workingCapitalCCCDateWithoutInventory
                    },
                    {
                        image: "WorkingCapitalMapWithoutInventory.JPG",
                        alt: "WorkingCapitalWithoutInventory",
                        isSelected: workingCapitalWithoutInventory
                    },
                    {
                        image: "WorkingCapitalMapWithInventory.JPG",
                        alt: "WorkingCapitalWithInventory",
                        isSelected: workingCapitalWithInventory
                    }

                ]
            }


            switch (step) {
                case 1:
                    return (
                        <PDetails
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 2:
                    return (
                        <RBDetails
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 3:
                    return (
                        <StmtSelect
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )

                case 4:
                    return (
                        <CTLDetails
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 5:
                    return (
                        <ChooseBankers
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleSelectedBankers={this.handleSelectedBankers}
                            values={values}
                        />
                    )
                case 6:
                    return (
                        <WorkingCapitalDetails
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 7:
                    return (
                        <ChooseViewPoints
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                case 8:
                    return (
                        <Confirmation
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            values={values}
                        />
                    )
                case 9:
                    return (
                        <AAstmtUpload
                            prevStep={this.prevStep}
                            nextStep={this.nextStep}
                            handleChange={this.handleChange}
                            values={values}
                        />
                    )
                default:
                // do nothing
            }
        }

    }
}