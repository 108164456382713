import React, { useEffect, useState, useRef } from 'react';
import { Container, Typography, Grid, TextField, Button, ButtonGroup, Table } from '@material-ui/core'
import {BASE_API_URL} from "../config";

const ChooseBankers = ({ prevStep, nextStep, handleSelectedBankers, values }) => {
	
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
   const dataFetchedRef = useRef(false);
  
  const Continue = e => {
    e.preventDefault();
    handleSelectedBankers(Array.from(values.bankers));
    console.log("*************************");
    console.log(values);
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }
  
   const addBanker = (group) => {     
     values.bankers.add(group);
     alert ("Banker :" + group.firstName + group.lastName + " Sucessfully added to the list");
  }
  
 
   useEffect(() => {
    setLoading(true);
     if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/bankers`, {
		        method: 'GET',
		          cache: 'no-cache',
                credentials: 'include'
		       
		    })    
      .then(response => response.json())
      .then(data => {
        setGroups(data);
        setLoading(false);
      }).catch(function(error){
           console.log("Error :" + error); 
         })
      
      
     
  }, []);
 
   let rowcount = 0;
   const groupList = groups.map(group => { 
	 
	 let clr = "#d1e0e6";
	 
    rowcount++;
    if (rowcount % 2 == 0) {
     clr = "white"
     }
    
    
    
    
    return (
	<tr key={group.id} bgcolor={clr}>
      <td style={{whiteSpace: 'nowrap'}}>{group.firstName} {group.lastName}</td>     
      <td style={{whiteSpace: 'nowrap'}}>{group.email}</td>
      <td>{group.title}</td>
      <td>{group.department}</td>      
      <td>
        <Button 
                onClick={() => addBanker(group)}              
                fullWidth
                variant="contained"
                color="secondary"
              >Select
              </Button>
      </td>
    </tr>
     
    )
    
  });
  
   
 
  
  
  
  return (
    <Container  component="main" >
      <div> 
        <Typography  component="h1" variant="h6"> 
          Generate Presentation:  Choose Relationship team contacts, Limit to 6
        </Typography>
        
          
       
        <Table className="mt-4">
          <thead>
          <tr>
            <th width="15%">Name</th>
            <th width="30%">Email</th>            
            <th width="15%">Title</th>
            <th width="15%">Department</th>
            <th width="15%">Action</th>
          </tr>    
          </thead>
          <tbody>
			  <tr>
				  <th width="10%"><hr /></th>
				  <th width="10%"><hr /></th>
				  <th width="10%"><hr /></th>
				  <th width="10%"><hr /></th>
				  <th width="10%"><hr /></th>
				  <th width="10%"><hr /></th>
			  </tr>    
          {groupList}
          </tbody>
        </Table>
        
        <br/>
        
        <Grid container spacing={2} >
			<Grid item xs={12} sm={6}>
              <Button 
                onClick={ Previous }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Previous
              </Button>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Button 
                onClick={ Continue }
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
              >
                Next
              </Button>
            </Grid>
          </Grid>
           <img src="/RelationShipTeam.jpg"  width="100%"/>
       </div>
    </Container>
    )
  
  
}


export default ChooseBankers