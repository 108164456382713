import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { useCookies } from 'react-cookie';
import axios from 'axios';
import {BASE_API_URL} from "../config";

let uploadedFileName;
let uploadedFileDownloadURL;
let uploadedFileType;
let processedFileName;
let mappingFileName;
let mappingFileURL;
let processedFileURL;

const AAstmtUpload = ({ prevStep, nextStep, handleChange, values }) => {
	
	let configRequest = JSON.stringify(values);
	//console.log(configRequest);
	
	const [authenticated, setAuthenticated] = useState(false); 
	const [file, setFile] = useState(null);
	const [file1, setFile1] = useState(null);
	const [loading, setLoading] = useState(false);
	const history = useNavigate();
	 
	let dataFetchedRef = useRef(false);
	
	  
	 useEffect(() => {
    setLoading(true);
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
      .then(response => response.text())
      .then(body => {
        if (body === '') {
          setAuthenticated(false);
          console.log("User not authenticated, redirecting the user to homepage");
           history('/');
        } else {        
          setAuthenticated(true);
        }
        setLoading(false);
      });
  }, []) 
  	
  	const handleSubmit = async e => {
    e.preventDefault();
    var fileUploadSuccess = document.querySelector('#FileUploadSuccess');
    fileUploadSuccess.innerHTML = "<h2>Parsing!!!!, please wait........</h2>";
   
    let res = await uploadFile(file); 
    uploadedFileName = res.uploadedFileName;
    uploadedFileDownloadURL = res.uploadedFileDownloadURL;
    uploadedFileType =  res.uploadedFileType;
    processedFileName= res.processedFileName;
    mappingFileName =res.mappingFileName;
    mappingFileURL = res.mappingFileURL;
    processedFileURL = res.processedFileURL;
    setFile1(uploadedFileName);
    
   
    fileUploadSuccess.innerHTML = "<li>Uploaded File Name : " + uploadedFileName +"</li>" +
    							  "<li>Processed file download URL :  " + processedFileURL +"</li>" +
    							  "<li>Uploaded File Type : " + uploadedFileType +"</li>" +
    							  "<li>Processed File Name : " + processedFileName +"</li>" +
    							  "<li>Service mapping log file name : " + mappingFileName +"</li>" +
    							  "<li>ervice mapping log file URL : " + mappingFileURL +"</li>";
    					 	
    console.log(res);
    console.log("fsafsfsaf" + file1);
    }
    
    const uploadFile = async file => {
    
     const formData = new FormData();
     
    formData.append("file", file);
    formData.append("statementType", "AA");
    formData.append("config",configRequest);
    console.log("File Name:" + file); 
    
    
    // send fetch along with cookies
			let response = await fetch(`${BASE_API_URL}/api/stmt/uploadFile`, {
		        method: 'POST',		       
		        body: formData,
                credentials: 'include'
		    });
		    
		    // server responded with http response != 200
	    	if(response.status !== 200)
	    		throw new Error('HTTP response code != 200');
	    		
	    	// read json response from server
	    	// success response example : {"error":0,"message":""}
	    	// error response example : {"error":1,"message":"File type not allowed"}
	    	let json_response = await response.json();
	    	
	        if(json_response.error === 1)
	           	throw new Error(json_response.message);	
	           	
	           	return json_response;
    
  };
   

  
   const handleOnChange = e => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
  };

	
	 
	 
	 return (<div>
	 
	  <Container>
      
         
         
         <div className="upload-container">
            <div className="upload-header">
                <h2>Upload Analysys Statement</h2>
            </div>
            <br/><br/>
            <div className="upload-content">
                 <Form onSubmit={handleSubmit} encType="multipart/form-data">                 		
                 		<FormGroup>
            				<Label for="file">Uplodad an pdf file</Label>            				
           			 		<Input type="file" onChange={handleOnChange} name="file"  className="file-input"  required  />           			 		
          				</FormGroup>
          				
		               <FormGroup>
		            		<Button color="primary" type="submit">Upload</Button>{' '}
		           			 <Button color="secondary" tag={Link} to="/bankers">Cancel</Button>
		          		</FormGroup>
          		</Form>
                
            </div>
        </div>
        
       <br/>
	<br/>
	<div width="60%" align="left" id="FileUploadSuccess" className="upload-container"> 
          
            <h2>Please upload a file to see the response</h2>
            
        </div>
         
              
         </Container>    
	
	 </div>)
	 
};

export default AAstmtUpload;