import React from 'react';
import './App.css';
import Home from './pages/Home';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import BankerList from './pages/BankerList';
import BankerEdit from './pages/BankerEdit';
import BankerImport from './pages/BankerImport';
import BankerActivate from './pages/BankerActivate';
import AAstmt from './pages/AAstmt';
import TMServiceList from './pages/TMServiceList';
import TMServiceEdit from './pages/TMServiceEdit';
import RecommendationList from './pages/RecommendationList'
import RecommendationEdit from './pages/RecommendationEdit'
import RecommendationAdd from './pages/RecommendationAdd'
import ParserConfig from './pages/ParserConfig';
import SynonymEdit from './pages/SynonymEdit';
import SynonymAdd from './pages/SynonymAdd';
import ProcessedFilesList from './pages/ProcessedFilesList'


const App = () => {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route path='/bankers' exact={true} element={<BankerList/>}/>
        <Route path='/bankers/:id' element={<BankerEdit/>}/>
        <Route path='/activate/:id' element={<BankerActivate/>}/>
        <Route path='/bankers/import' exact={true} element={<BankerImport/>}/>
        <Route path='/bankers/aastmt' exact={true} element={<AAstmt/>}/>
        <Route path='/bankers/tmservice' exact={true} element={<TMServiceList/>}/>
        <Route path='/recommend/new' exact={true} element={<TMServiceEdit/>}/>
        <Route path='/recommend/mapping' exact={true} element={<RecommendationList/>}/>
        <Route path='/recommendation/edit/:id' element={<RecommendationEdit/>}/>
        <Route path='/recommendation/add/:id' element={<RecommendationAdd/>}/>
        <Route path='/synonym/edit/:id/:name' element={<SynonymEdit/>}/>
        <Route path='/synonym/add/:id/:name/:aname' element={<SynonymAdd/>}/>
        <Route path='/bankers/pmapping' element={<ParserConfig/>}/>
        <Route path='/bankers/pfiles' element={<ProcessedFilesList/>}/>
      </Routes>
    </Router>
  )
}

export default App;
