import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Container, Form, FormGroup, Input, Label } from 'reactstrap';
import AppNavbar from './AppNavbar';
import { useCookies } from 'react-cookie';
import {BASE_API_URL} from "../config";

const BankerEdit = () => {
  const initialFormState = {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    bankingVetical: '',
    branchName: '',
    department: '',
    primaryContactNumber: '',
    primaryContactType: '',
    secondaryContactNumber: '',
    secondaryContactType: '',
    bankName: 'Regions'
  };
  const [group, setGroup] = useState(initialFormState);
  const navigate = useNavigate();
  const { id } = useParams();
  const [cookies] = useCookies(['XSRF-TOKEN']);

  useEffect(() => {
    if (id !== 'new') {
      fetch(`${ BASE_API_URL }/api/bankers/${id}`, {credentials: 'include'})
        .then(response => response.json())
        .then(data => setGroup(data));
    }
  }, [id, setGroup]);

  const handleChange = (event) => {
    const { name, value } = event.target

    setGroup({ ...group, [name]: value })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();

    let response = await fetch(`${ BASE_API_URL }/api/bankers${group.id ? `/${group.id}` : ''}`, {
      method: group.id ? 'PUT' : 'POST',
      headers: {
        'X-XSRF-TOKEN': cookies['XSRF-TOKEN'],
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(group),
      credentials: 'include'
    });
    
    console.log("Response state: " + response.status);
     if(response.status === 403){
				alert("Not Authorized to update or add a banker!!!!");
			}
	else{
    setGroup(initialFormState);
    navigate('/bankers');
    }
  }

  const title = <h2>{group.id ? 'Edit Banker' : 'Add Banker'}</h2>;

  return (<div>
      <AppNavbar/>
      <Container>
       
        {title}
        <Form onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="firstName">First Name</Label>
            <Input type="text" name="firstName" id="firstName" value={group.firstName || ''}
                   onChange={handleChange} autoComplete="firstName"/>
          </FormGroup>
          <FormGroup>
            <Label for="lastName">Last Name</Label>
            <Input type="text" name="lastName" id="lastName" value={group.lastName || ''}
                   onChange={handleChange} autoComplete="lastName"/>
          </FormGroup>
          <FormGroup>
            <Label for="email">Email</Label>
            <Input type="text" name="email" id="email" value={group.email || ''}
                   onChange={handleChange} autoComplete="email"/>
          </FormGroup>
          <FormGroup>
            <Label for="title">Title</Label>
            <Input type="text" name="title" id="title" value={group.title || ''}
                   onChange={handleChange} autoComplete="title"/>
          </FormGroup>
          <FormGroup>
            <Label for="bankingVetical">Banking Vertical</Label>
            <Input type="text" name="bankingVetical" id="bankingVetical" value={group.bankingVetical || ''}
                   onChange={handleChange} autoComplete="bankingVetical"/>
          </FormGroup>
          <FormGroup>
            <Label for="branchName">branchName</Label>
            <Input type="text" name="branchName" id="branchName" value={group.branchName || ''}
                   onChange={handleChange} autoComplete="branchName"/>
          </FormGroup>
          <FormGroup>
            <Label for="department">Department</Label>
            <Input type="text" name="department" id="department" value={group.department || ''}
                   onChange={handleChange} autoComplete="department"/>
          </FormGroup>
          <FormGroup>
            <Label for="primaryContactNumber">Primary Contact Number</Label>
            <Input type="text" name="primaryContactNumber" id="title" value={group.primaryContactNumber || ''}
                   onChange={handleChange} autoComplete="primaryContactNumber"/>
          </FormGroup>
          <FormGroup>
            <Label for="primaryContactType">Primary Contact Type</Label>
            <Input type="text" name="primaryContactType" id="primaryContactType" value={group.primaryContactType || ''}
                   onChange={handleChange} autoComplete="primaryContactType"/>
          </FormGroup>
           <FormGroup>
            <Label for="secondaryContactNumber">Secondary Contact Number</Label>
            <Input type="text" name="secondaryContactNumber" id="title" value={group.secondaryContactNumber || ''}
                   onChange={handleChange} autoComplete="secondaryContactNumber"/>
          </FormGroup>
          <FormGroup>
            <Label for="secondaryContactType">Secondary Contact Type</Label>
            <Input type="text" name="secondaryContactType" id="secondaryContactType" value={group.secondaryContactType || ''}
                   onChange={handleChange} autoComplete="secondaryContactType"/>
                    
          </FormGroup>
          <FormGroup>
            <Label for="bankName">Bank Name</Label>
            <Input type="text" name="bankName" id="bankName" value={group.bankName || 'Regions'}
                   onChange={handleChange} autoComplete="bankName"/>
                    
          </FormGroup>
         
                            
          
          <FormGroup>
            <Button color="primary" type="submit">Save</Button>{' '}
            <Button color="secondary" tag={Link} to="/bankers">Cancel</Button>
          </FormGroup>
        </Form>
      </Container>
    </div>
  )
};

export default BankerEdit;
