import React, { useEffect, useState, useRef } from 'react';
import '../App.css';
import AppNavbar from './AppNavbar';
import { Link } from 'react-router-dom';
import { Button, Container } from 'reactstrap';
import { useCookies } from 'react-cookie';
import {BASE_API_URL} from "../config";

const Home = () => {

  const [authenticated, setAuthenticated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(undefined);
  const [cookies] = useCookies(['XSRF-TOKEN']);
  const dataFetchedRef = useRef(false);
  
     
  useEffect(() => {
    setLoading(true);
    if (dataFetchedRef.current) return;
      dataFetchedRef.current = true;
    fetch(`${BASE_API_URL}/api/user`, { credentials: 'include' })
      .then(response => response.text())
      .then(body => {
        console.log(body);
        if (body === '') {
          setAuthenticated(false);
        } else {
          setUser(JSON.parse(body));
          setAuthenticated(true);
        }
        setLoading(false);
      });
  }, [setAuthenticated, setLoading, setUser])

  const login = () => {
    let port = (window.location.port ? ':' + window.location.port : '');
    // if (port === ':3000') {
      port = ':8080';
    // }
    window.location.href = `//${window.location.hostname}${port}/private`;
  }

  const logout = () => {
    fetch(`${BASE_API_URL}/api/logout`, {
      method: 'POST', credentials: 'include',
      headers: { 'X-XSRF-TOKEN': cookies['XSRF-TOKEN'] }
    })
      .then(res => res.json())
      .then(response => {
        window.location.href = `${response.logoutUrl}?id_token_hint=${response.idToken}`
          + `&post_logout_redirect_uri=${window.location.origin}`;
      });
  }

  const message = user ?
    <p></p> :
    <p>Please log in to manage Treasury and Liquidity Services</p>;
    
    const otherMessage = user ?
    <img src="/Banking.jpg" width="100%" height="60%"/> :
    <p></p>;

  const button = authenticated ?
    <div></div> : <Button color="primary" onClick={login}>Login</Button>;

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <AppNavbar />
      <Container fluid>
        {message}
        {otherMessage}
        {button}
      </Container>
    </div>
  );
}

export default Home;
