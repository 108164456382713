import React, { useEffect, useState, useRef } from 'react';
import AppNavbar from './AppNavbar';
import { useParams, useNavigate, Link } from 'react-router-dom';
 
import { Button, ButtonGroup, Container, Table, Form, FormGroup, Input, Label } from 'reactstrap';
import {BASE_API_URL} from "../config";
 

const RecommendationEdit = () => {
	
	const [loading, setLoading] = useState(false);
	const dataFetchedRef = useRef(false);
	const [groups, setGroups] = useState([]);
	const { id } = useParams();	
	
  console.log("Fetching recommendation mapping for service Id:" + id);
  
  useEffect(() => {
		setLoading(true);
		if (dataFetchedRef.current) return;
		dataFetchedRef.current = true;
		let apiResponse = fetch(`${BASE_API_URL}/api/recommendation/`+id+'/recommendations', {
			method: 'GET',
			credentials:'include'
		})
			.then(response => response.json())
			.then(data => {
				setGroups(data);
				setLoading(false);
			}).catch(function(error) {
				console.log("Error :" + error); 
			})

		console.log(apiResponse);

	}, []);
	
	if (loading) {
    return <p>Loading...</p>;
  }
  
  const remove = async (id,name) => {
	
	let response = await fetch(`${BASE_API_URL}/api/recommendation/recommendations/`+id+'/'+name, {
		       method: 'DELETE',  credentials: 'include'
    });
    
    if(response.status == 403){
				alert("Not Authorized to delete an baker!!!!");
			}
	if(response.status === 204){
		 window.location.reload();
	}		
    
   
   };
  
  const groupList = groups.map(group => {
	
	
	 return <tr key={group.id}>
		       <td style={{whiteSpace: 'nowrap'}}>{group.service.name} </td>     
		       <td style={{whiteSpace: 'nowrap'}}>{group.name} </td>  
		       <td>
			        <ButtonGroup>
			          <Button size="sm" color="primary"  onClick={() => remove(id, group.name )}>Delete</Button> &nbsp;&nbsp;
			        </ButtonGroup>
			    </td>
		    </tr>
	 
	 
  });
  
  

  return (<div>
      <AppNavbar/>
      <Container>
      <div> 
      		 <Table className="mt-4">
		          <thead>
		          <tr>
		            <th width="40%">Service Name</th>
		            <th width="40%">Mapped Recommendation</th>
		            <th width="20%">Actions</th>
		          </tr>
		          </thead>
		          <tbody>
		          	{groupList}
		          </tbody>
	        </Table> 
       	</div>
        
      </Container>
    </div>
  )
};

export default RecommendationEdit;
